import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, MenuItem, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { searchPlaces } from '../Functions/Get-query/searchPlaces';
import { getCountryList } from '../Functions/Get-query/getCountryList';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '16px',
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const parseFromElasticSearchResults = (results) => {
  const parsedCitiesArray = [];
  results.forEach((res) => {
    parsedCitiesArray.push({
      loc_id: res._source.id,
      city: res._source.city || '',
      department: res._source.department || '',
      country: res._source.country || '',
      region: res._source.region || '',
      place_type: res._source.place_type,
    });
  });
  return parsedCitiesArray;
};

const PlacesAutoComplete = ({
  name,
  label,
  multiple = true,
  max = 3,
  onFormChange,
  formData,
  errors,
  locationsSelected,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [placeData, setPlaceData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  useEffect(() => {
    if (!isOpen) {
      getCountryList().then((response) => {setCountryList(response)})
    }
  }, [isOpen]);

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const onTextChanged = (event) => {
    if (!selectedCountry) {
      setOpen(false);
      return;
    }
    let query = event.target.value;
    query = capitalizeFirstLetter(query.toLowerCase());
    event.target.value = query;
    const destinationToRemove = [];
    let listDestinationsWithOutAlreadySelected = null;

    if (query === null || query === '') {
      if (placeList !== []) setPlaceList([]);
      setOpen(false);
      return;
    }
    setLoading(true);

    searchPlaces(query, selectedCountry).then((response) => {
      if (isObject(response)) {
        if (locationsSelected) {
          locationsSelected.forEach((element) => {
            destinationToRemove.push(element.id);
          });
          listDestinationsWithOutAlreadySelected = response.results.filter(
            (destination) =>
              !destinationToRemove.includes(destination._source.id),
          );
        } else if (
          formData.destinations !== null &&
          formData.destinations !== undefined
        ) {
          formData.destinations.forEach((element) => {
            destinationToRemove.push(element);
          });
          listDestinationsWithOutAlreadySelected = response.results.filter(
            (destination) =>
              !destinationToRemove.includes(destination._source.id),
          );
        } else if (
          formData.locations !== null &&
          formData.locations !== undefined
        ) {
          formData.locations.forEach((element) => {
            destinationToRemove.push(element);
          });
          listDestinationsWithOutAlreadySelected = response.results.filter(
            (destination) =>
              !destinationToRemove.includes(destination._source.id),
          );
        }

        if (response.total > 0) {
          const newListToParse =
            listDestinationsWithOutAlreadySelected || response.results;
          const newList = parseFromElasticSearchResults(newListToParse);
          setPlaceList(newList.slice(0, 100));
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
      setLoading(false);
    });
  };

  const getLocationDisplayName = (destination_place) => {
    switch(destination_place.place_type) {
      case 'country':
        return destination_place.country;
      case 'region':
        return `${destination_place.region}, ${destination_place.country}`;
      case 'department':
        return `${destination_place.department}, ${destination_place.country}`;
      case 'city':
        if(destination_place.department!=''){
          return `${destination_place.city}, ${destination_place.department}, ${destination_place.country}`;

        }
        else if(destination_place.region!==''){
          return `${destination_place.city}, ${destination_place.region}, ${destination_place.country}`;

        }else{
          return `${destination_place.city}, ${destination_place.country}`;
        }
      default:
        return destination_place.city
    }
  };

  const handleCountrySelection = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    const place_id = countryList.find(x=>x.name==country).id
    if (!multiple) {
      onFormChange({
        target: {
          name,
          value: place_id,
        },
      });
    }  else {
        const destArray = [place_id];
        onFormChange({
          target: {
            name,
            value: destArray,
          },
        });
    }
    
    setPlaceList([]);
  };

  const handleLocationSelection = (event, data) => {
    if (!multiple) {
      onFormChange({
        target: {
          name,
          value: data?.loc_id || null,
        },
      });
    } else if (data.length <= max) {
      if (data === null) {
        onFormChange({
          target: {
            name,
            value: [],
          },
        });
      } else {
        const destArray = data.map((d) => d.loc_id);
        setPlaceData(data);
        onFormChange({
          target: {
            name,
            value: destArray,
          },
        });
      }
    }
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <TextField
          select
          label="Sélectionner le pays"
          value={selectedCountry}
          onChange={handleCountrySelection}
          variant="outlined"
          error={!!errors['country']}
          helperText={errors['country'] || ''}
        >
          {countryList.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <Autocomplete
          id={name}
          multiple={multiple}
          limitTags={3}
          disabled={!selectedCountry}
          open={isOpen}
          noOptionsText="En chargement..."
          getOptionLabel={getLocationDisplayName}
          options={placeList}
          loading={isLoading}
          fullWidth
          key={(option) => option.id}
          getOptionDisabled={() => (placeData.length >= max ? true : false)}
          onChange={handleLocationSelection}
          PopperComponent={(props) => (
            <Popper
              {...props}
              placement="bottom-start"
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'window',
                },
              }}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={onTextChanged}
              onBlur={onTextChanged}
              error={!!errors[name]}
              variant="outlined"
              label={label}
              placeholder=""
            />
          )}
        />
      </div>
      
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

PlacesAutoComplete.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

PlacesAutoComplete.defaultProps = {
  max: 3,
  multiple: false,
};

export default PlacesAutoComplete;
