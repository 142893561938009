import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary, OutlinedInput, InputLabel, FormControl, InputAdornment,IconButton } from '@material-ui/core';
import Footer from '../UI-Component/Footer';
import { getFaqs } from '../Functions/Get-query/getFaqs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Search from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "100px",
    },
    homeContainer: {
      backgroundColor: "whitesmoke",
      paddingTop: "40px",
      paddingBottom: "50px"
    },
    details: {
      fontWeight: 'normal'
    },
    heading: {

    },
    rose: {
      color: theme.palette.secondary,
    },
    accordion: {
      borderRadius: '10px!important',
      margin: "20px 0px",
      border: 'none',
      boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2)',
      "&:before": {
        backgroundColor: "transparent"
      }
    },
  }),
);

const Faq = (props) => {
  const { type } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [categorie, setCategorie] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchData = async () => {
    try {
      const result = await getFaqs(type,keyword);
      setData(result.faqs);
      setCategorie(result.categorie)
    } finally { }
  };
  const onSearchChange = (event) => {
    setKeyword(event.target.value)
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
          <Grid container className={classes.homeContainer}>
            <Grid item md={2} xs={12}>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid item lg={12} className={classes.faqContainer}>
                <div>
                  <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>{categorie?.heading}</Typography>
                  <Typography variant="h2" style={{ textAlign: 'center', width: '100%' }}>Nous répondons à vos questions</Typography>
                </div>
             
                <Grid container>
                  <Grid item xs={12} className='mb-2'>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="standard-adornment-search">Rechercher par mot-clé</InputLabel>
                      <OutlinedInput
                        labelWidth={160}
                        id="standard-adornment-search"
                        type='text'
                        value={keyword}
                        onChange={(event) => onSearchChange(event)}
                        onKeyDown={fetchData}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={fetchData}
                              onMouseDown={fetchData}
                            >
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {data.map((item, i) =>
                      <>
                        <Accordion expanded={expanded === `panel-${i}`} onChange={handleChange(`panel-${i}`)} className={classes.accordion}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${i}-bh-content`}
                            id={`panel-${i}-bh-header`}
                          >
                            <Typography className={classes.heading}>{item.question}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              component="p"
                              dangerouslySetInnerHTML={{
                                __html: `${item.answer}`,
                              }}
                              className={classes.details}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </>

                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Faq;
