import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import PlacesAutoComplete from '../../PlacesAutoComplete';
import WorldCheckbox from '../Partner/WorldCheckbox';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
  },
  html: {
    height: '100%',
    margin: 0,
  },
}));

const ThirdStep = ({
  userType,
  errors,
  isGlobalChecked,
  onGlobalChange,
  onFormChange,
  formData,
}) => {
  const classes = useStyles(theme);
  return (
    <>
      <Grid item xs={12}>
        {userType === 'Candidate' ? (
          <>
            <Typography variant="h2" className={classes.smallTitle}>
              Dans quelle ville habitez-vous?
            </Typography>
            <PlacesAutoComplete
              name="country"
              label="Choisir votre ville ou pays *"
              multiple
              max={1}
              onFormChange={onFormChange}
              errors={errors}
              formData={formData}
            />
          </>
        ) : userType === 'Enterprise' ? (
          <>
            <Typography variant="h2" className={classes.smallTitle}>
              Où se trouvent vos emplois à combler ?
            </Typography>
            <PlacesAutoComplete
              name="locations"
              label="Choisir votre/vos ville(s) ou pays*"
              multiple
              max={3}
              onFormChange={onFormChange}
              errors={errors}
              formData={formData}
            />
          </>
        ) : (
          <>
            <Typography variant="h2" className={classes.smallTitle}>
              Ville/région/pays où vous offrez vos services : (choisissez 4
              lieux différents)?
            </Typography>
            <WorldCheckbox checked={isGlobalChecked} onChange={onGlobalChange} />
            {!isGlobalChecked
              ? (
                <PlacesAutoComplete
                  name="locations"
                  label="Choisir le ou les lieux où vos services sont offerts *"
                  multiple
                  max={4}
                  onFormChange={onFormChange}
                  errors={errors}
                  formData={formData}
                />
              )
              : null }

          </>
        )}
      </Grid>
    </>
  );
};

export default ThirdStep;
