import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const searchPlaces = (value, country) => (
  axios.get(`${apiUrl()}/places?query=${value}*&country=${country}`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default searchPlaces;
