import React, { useState } from 'react';
import {
  Fab, Button, Grid, makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PlacesAutoComplete from '../../../Form/PlacesAutoComplete';
import theme from '../../../theme';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  '& > *': {
    zIndex: 100,
    position: 'relative',
  },

  submit: {
    margin: theme.spacing(3, 0, 3),
  },
}));

const AddCountry = (props) => {
  const [open, setOpen] = React.useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    destinations: null,
  });

  const { updateUser } = useSessionContext();

  const {
    show,
    closeModal,
    editMode,
    destinations,
    currentDest,
    refreshDestinations,
    locationsSelected,
  } = props;

  const handleClose = () => {
    closeModal(false);
  };
  const errors = {};

  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.country || verifyAll) {
      if (formData.country === null) {
        errors.country = 'Le lieu de résidence est requis!';
      }
    }

    setFormErrors({
      ...errors,
      verifyAll
    });

    return Object.keys(errors).length < 1;
  };

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      },
      validateForm(),
    );
  };

  const handleSubmit = () => {
    let updatedDestinations = [];

console.log(formData)
    if (editMode === 'Add' && formData.destinations !== null && formData.destinations.length > 0 ) {
      updatedDestinations = updatedDestinations.concat(formData.destinations);
    }
    locationsSelected.forEach((destination) => {
      if (editMode === 'Edit' && destination.id === currentDest) {
        if ( formData.destinations !== null && formData.destinations.length > 0 ) {
          updatedDestinations = updatedDestinations.concat(formData.destinations);
        }
      } else {
        updatedDestinations.push(destination.id);
      }
    });

    setFormData({
      destinations: null,
    });
    const raw = {
      user: {
        destinations: updatedDestinations,
      },
    };
    if (validateForm(true)) {
      updateUser(raw)
        .then((response) => {
          if (response.status === 400) {
            if (response.data.error) {
              setFormErrors({
                ...errors,
                verifyAll: true
              });
            }
          }
          if (response.status === 200) {
            handleClose();
            refreshDestinations();
          }
        });
    }
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);
  return (
    <div>

      <Dialog
        className={classes.root}
        open={show ? open === false : open === true}
        onClose={handleClose}
        aria-labelledby="Destination"
        fullScreen={fullScreen}
        large="500px"
      >
        <Grid container spacing={2}>
          <DialogContent>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 0',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon/>
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle>Choisir une destination dans la liste</DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <PlacesAutoComplete
                name="destinations"
                label="Choisir une ville *"
                multiple
                onFormChange={onFormChange}
                errors={errors}
                formData={formData}
                locationsSelected={locationsSelected}
              />
            </Grid>
            <DialogActions>
              <Button onClick={handleSubmit}>Valider</Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AddCountry;
