import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Card, CardHeader, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CountryModal from './FormModal/CountryModal';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  destinationCard: {
    width: '100%',
    borderTop: 'none',
  },
  destinationTitle: {
    '& span': {
      fontSize: '1.1rem',
    },
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
}));

const Destinations = (props) => {
  const { locationInfo, typeOfUser } = props;
  const [editMode, setEditMode] = useState('Edit');
  const [show, setShow] = useState(false);
  const [editedDestination, setEditedDestination] = useState('');

  const { user, updateUser, refreshUserData } = useSessionContext();

  const deleteDestination = (dest) => {
    const updatedDests = [];

    if (user.type_of_user === 'Candidate') {
      user.candidate_destinations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }
    if (user.type_of_user === 'Enterprise') {
      user.job_locations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }
    if (user.type_of_user === 'Partner') {
      user.partner_locations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }

    const raw = {
      user: {
        destination_to_delete: dest.id,
        destinations: updatedDests,
        delete_destination: true,
      },
    };

    updateUser(raw).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.log('error deleting destination');
        }
      }
      if (response.status === 200) {
        refreshUserData();
      }
    });
  };

  const getDestinationLabel= (destination_place) => {
    switch(destination_place.place_type) {
      case 'country':
        return destination_place.country;
      case 'region':
        return `${destination_place.region}, ${destination_place.country}`;
      case 'department':
        return `${destination_place.department}, ${destination_place.country}`;
      case 'city':
        if(destination_place.department!=null){
          return `${destination_place.city}, ${destination_place.department}, ${destination_place.country}`;

        }
        else if(destination_place.region!=null){
          return `${destination_place.city}, ${destination_place.region}, ${destination_place.country}`;

        }else{
          return `${destination_place.city}, ${destination_place.country}`;
        }
      default:
        return destination_place.city
    }
  }

  const openCreateModal = () => {
    setEditMode('Add');
    setEditedDestination('');
    setShow(true);
  };

  const closeModal = () => setShow(false);
  const classes = useStyles(theme);

  const displayDestinations = () => {
    if (user.type_of_user === 'Candidate') {
      return user.candidate_destinations.map((dest) => (
        <Grid container alignItems="center" key={dest.id}>
          <Grid item xs={10}>
              <CardHeader
                title={getDestinationLabel(dest)}
                className={classes.destinationTitle}
              />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Fab
              id={`deleteDestination-${dest.id}`}
              aria-label="Delete"
              onClick={() => deleteDestination(dest)}
              color="secondary"
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        </Grid>
      ));
    }
    if (user.type_of_user === 'Enterprise') {
      return user.job_locations.map((dest) => (
        <Grid container alignItems="center" key={dest.id}>
          <Grid item xs={10}>
            <CardHeader
                title={getDestinationLabel(dest)}
                className={classes.destinationTitle}
              />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Fab
              id={`deleteDestination-${dest.id}`}
              aria-label="Delete"
              color="secondary"
              onClick={() => deleteDestination(dest)}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        </Grid>
      ));
    }
    if (user.type_of_user === 'Partner') {
      if (user.global) {
        return (
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <CardHeader
                  title={'Partout dans le monde'}
                  className={classes.destinationTitle}
                />
            </Grid>
          </Grid>
        );
      } else {
        return user.partner_locations.map((dest) => (
          <Grid container alignItems="center" key={dest.id}>
            <Grid item xs={10}>
                <CardHeader
                  title={getDestinationLabel(dest)}
                  className={classes.destinationTitle}
                />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {user.global ? (
                <Fab
                  id={`deleteDestination-${dest.id}`}
                  aria-label="Delete"
                  color="secondary"
                  disabled={true}
                >
                  <DeleteIcon />
                </Fab>
              ) : (
                <Fab
                  id={`deleteDestination-${dest.id}`}
                  aria-label="Delete"
                  color="secondary"
                  onClick={() => deleteDestination(dest)}
                >
                  <DeleteIcon />
                </Fab>
              )}
            </Grid>
          </Grid>
        ));
      }
    }
    return null;
  };

  const locations =
    user.type_of_user === 'Candidate'
      ? user.candidate_destinations
      : user.job_locations || user.partner_locations;

  return (
    <Grid container>
      <Card
        square
        className={classes.rootCard}
        elevation={0}
        variant="outlined"
      >
        <Grid container alignItems="center">
          <Grid item xs={10}>
            {typeOfUser === 'Candidate' && (
              <CardHeader
                title="Destinations"
                subheader="Sélectionner le(s) localisation(s) où vous souhaitez trouver un emploi"
                className={classes.profileTabTitle}
              />
            )}
            {typeOfUser === 'Enterprise' && (
              <CardHeader
                title="Localisation"
                subheader="Sélectionner le(s) emplacement(s) (ville ou région ou pays) de vos métiers à combler"
                className={classes.profileTabTitle}
              />
            )}
            {typeOfUser === 'Partner' && (
              <CardHeader
                title="Localisation"
                subheader="Sélectionner le(s) emplacement(s) (ville ou région ou pays) où vous offrez vos services"
                className={classes.profileTabTitle}
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 0',
            }}
          >
            {typeOfUser === 'Partner' ? (
              <Fab
                className="add-localisation-btn"
                id="addDestination"
                color="secondary"
                aria-label="add"
                disabled={user.global}
                onClick={() => {
                  refreshUserData();
                  openCreateModal();
                }}
              >
                <AddIcon />
              </Fab>
            ) : (
              <Fab
                className="add-localisation-btn"
                id="addDestination"
                color="secondary"
                aria-label="add"
                onClick={() => {
                  refreshUserData();
                  openCreateModal();
                }}
              >
                <AddIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      </Card>
      <Card
        square
        className={classes.destinationCard}
        elevation={0}
        variant="outlined"
      >
        {displayDestinations()}
      </Card>

      <CountryModal
        currentDest={editedDestination}
        destinations={locationInfo}
        editMode={editMode}
        typeOfUser={typeOfUser}
        closeModal={closeModal}
        show={show}
        refreshDestinations={refreshUserData}
        locationsSelected={locations}
      />
    </Grid>
  );
};

export default Destinations;
