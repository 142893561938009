import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Hidden,
  ListItemAvatar,
  ListItemText,
  Fab,
  Link,
  Avatar,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import carouselImg1 from '../../images/carousel/metier-image-1.jpg';
import carouselImg2 from '../../images/carousel/metier-image-2.jpg';
import carouselImg3 from '../../images/carousel/metier-image-3.jpg';
import carouselImg4 from '../../images/carousel/metier-image-4.jpg';
import target from '../../images/icon/target.svg';
import handshake from '../../images/icon/handshake.png';
import strength from '../../images/icon/strength.png';
import algo from '../../images/icon/algo.svg';
import rocket from '../../images/icon/rocket.svg';
import vision from '../../images/icon/vision.png';
import teamImage1 from '../../images/moovejob-team-img-1.jpeg';
import teamImage2 from '../../images/moovejob-team-img-2.jpeg';
import iconMapCandidate from '../../images/icon-map-candidate.png';
import iconInterconnexionJob from '../../images/icon-interconnexion-job.png';
import iconEnterpriseCandidateMatch from '../../images/icon-enterprise-candidate-match.png';
import logoVDQ from '../../images/logo_vdq.png';
import logoDesJardins from '../../images/logo-desjardins.png';
import logoAirCanada from '../../images/logo_air_canada.png';
import logoMajorelle from '../../images/logo_majorelle_mobility.png';
import logoVilleShawinigan from '../../images/logo_ville_shawinigan.png';
import logoPatrimoineRH from '../../images/logo_patrimoine_rh.png';
import logoHarfangImmigration from '../../images/logo_harfang_immigration.png';
import logoLeBonJack from '../../images/logo_le_bon_jack.png';
import referencementIllu from '../../images/referencement-illu.png';
import theme from '../theme';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from '../UI-Component/Footer';
import useSessionContext from '../sessions/useSessionContext';
import NewsCarousel from '../UI-Component/MainPageCarousel';
import CountUpAnimation from '../UI-Component/CountUpAnimation';
import { format } from 'date-fns';
import { fr } from "date-fns/locale";
import usa from '../../images/photos/usa.jpg';
import canada from '../../images/photos/canada.jpg';
import france from '../../images/photos/france.jpg';
import spain from '../../images/photos/spain.jpg';
import greatBritain from '../../images/photos/great-britain.jpg';
import australia from '../../images/photos/australia.jpg';
import germany from '../../images/photos/germany.jpg';
import luxembourg from '../../images/photos/luxembourg.jpg';
import belgium from '../../images/photos/belgium.jpg';
import switzerland from '../../images/photos/switzerland.jpg';
import SignupMenu from '../UI-Component/SignupMenu';
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles((theme) => ({
  main:{
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden'
    },
  },
  homeContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20vh',
    justifyContent: 'space-evenly',
    paddingBottom: '50px',
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      marginTop: '5vh',
    },
  },
  iconAction:{
    marginRight: 5,
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
  },
  linkDemo: {
    color: '#e5087e',
    textTransform: 'none',
    fontSize: '15px'
  },
  flexHeightDesktop: {
    height: 'auto',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  flexAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  bgHero: {
    position: 'relative',
    height: '90vh',
    '& > *': {
      position: 'absolute',
      zIndex: 1,
    },
    '& .carousel-root': {
      width: '100%',
      height: '100%',
      '& .slider-wrapper':{
        height: '100%',
      }
    },
    '& .carousel .slide': {
      height: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
      '& .carousel .slide': {
        height: '60vh',
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: '80vh',
      '& .carousel .slide': {
        height: '80vh',
      },
    },
  },
  carouselContainer: {
    backgroundRepeat: 'no-repeat',
    height: '90vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '82vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '91vh',
    },
  },
  videoContainer: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
    '&::after': {
      paddingTop: '56.25%',
      display: 'block',
      content: '""',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },

  bgGrey: {
    backgroundColor: '#f9f9f9!important'
  },
  media: {
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '20px'
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '375px',
    '& p': {
      textAlign: 'left',
      margin: '0 0 0 30%',
    },
  },
  logoStyle: {
    maxWidth: '300px',
    maxHeight: '200px',
    margin: '0 auto',
  },
  iconStyle:{
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    "& .MuiAvatar-img":{
      height: "unset"
    }
  },
  logoStyleSmall: {
    maxWidth: '30vh',
    margin: '0 auto',
  },
  slogan: {
    fontWeight: 'bold',
    margin: 0,
  },
  subTitle: {
    margin: '3vh 0 6vh',
    paddingTop: '5vh',
    textAlign: 'center',
    width: '100%',
  },
  statisticNumber: {
    fontSize: 65,
    textAlign: 'center',
    width: '100%',
    color: "#e5087e",
    fontWeight: 'bold',
  },
  statisticTitle: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
  },
  reverseButtonStyle: {
    fontSize: '1.3em',
    maxWidth: '30%',
    color: '#E5087E',
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  listMoove: {
    position: 'relative',
    margin: '0',
    '&::before': {
      content: '"."',
      position: 'absolute',
      top: '-18px',
      left: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '30px',
      width: '30px',
      height: '100%',
      fontSize: 45,
      color: theme.palette.secondary.main,
    },
    '& p': {
      margin: '0',
      marginLeft: '20px',
      padding: '0px',
    },
  },
  responsiveMargin: {
    margin: '4vh 0',
  },

  html: {
    height: '100%',
    margin: 0,
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
  scrollWithOffset: {
    position: 'relative',
    top: '-7vw',
    paddingTop: '7vw',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px!important',
      paddingRight: '10px!important'
    },
  },
  rowDestinationContainer:{
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15
    },
  },
  container: {
      padding: '1rem 1rem 1.6rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
      position: 'relative',
      zIndex: 1,
      transition: 'all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: '#fff',
        border: '1px solid #fff',
        boxShadow: '0 20px 30px rgba(0, 0, 0, 0.04)',
        borderRadius: '1.6rem',
      }
  },
  containerPhoto: {
    height: 'auto',
    justifyContent: 'center',
    
  },
  destinationImageContainer: {
    width: '100%',
    height: '8.5rem',
    borderRadius: '1.2rem',
    filter: 'drop-shadow(0 20px 30px rgba(0, 0, 0, 0.04))',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: '250px!important',
      width: '350px!important',
    },
  },
  destinationImage: {
    width: '100%',
    height: '100%',
    borderRadius: '1.2rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  subTitleDest: {
    textAlign: 'center',
    fontWeight: 700,
   
  },
  secondSubTitle:{
    textAlign: 'center',
    width: '100%',
    textAlign: 'left',
    margin: '0px'
  },
  reveContainer: {
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '20px 0px',
    },
  },
  listItemIcon: {
    '& .MuiListItemAvatar-root':{
      minWidth: '70px'
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      padding: '0px!important',
      '& .MuiListItemAvatar-root':{
        marginTop: '12px'
      }
    },
  },
  advantageContainer:{
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
  },
  pink:{
    color: '#E4087E'
  },
  panelSlogan: {
    margin: "0px 20px"
  },
  panelAction: {
    margin: "0px 20px",
    marginBottom: "15px",
    width: '92%',
    [theme.breakpoints.down('md')]: {
      margin: "0px auto",
      marginBottom: "3vh",
      "& > ul":{
        display: "inline",
        "& > li":{
          margin: "10px 0px",
          "& > button":{
            width: "100%"
          }
        }
      }
    }
  },
  containerHomeText:{
    alignItems: 'flex-start!important',
    backgroundColor: '#ffffffde!important',
    borderRadius: '30px',
    maxWidth: '500px!important'
  },
  h1Slogan:{
    textAlign: "left",
    fontSize: "1.5em",
    marginBottom: "0px"
  },
  btnDisplayFlex: {
    display: `flex`,
    justifyContent: `center`,
    margin: '0px',
    height: '100%',
    '& li': {
      justifyContent: 'center',
      marginRight: '10px',
      padding: '0px',
      width: '100%!important',
      textAlign: 'center',
      
    },
  },
  iconAction:{
    marginRight: 5,
    width: '100%',
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
    '& a:hover': {
      textDecoration: 'none'
    },
  },
  bulletPoint: {
    fontSize: '1.1em',
    fontWeight: 'normal',
    '& span': {
      fontSize: '1em',
    },
  },
  referenceContainer:{
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 10
    },
    
  },
  referenceText:{
    fontWeight: 700,
    textAlign: 'center',
    margin: 5,
  },
  buttonStyle: {
    minWidth: 'unset',
    lineHeight: '18px',
    padding: '0px 12px',
    height: 44,
    margin: '7px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '15px'
    },
    '&:hover': {
      '& a': {
        color: '#E5087E',
        textTransform: 'none',
        textDecoration: 'none',
      }
    },
    '& a': {
      fontWeight: 600,
      color: '#fff',
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        color: '#E5087E',
      },
    },
  },
  referenceBtnLink: {
    color: '#e5087e',
    textTransform: 'none',
    fontSize: '16px',
    '&:hover': {
      color: '#E5087E',
    },
  },
  bold: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  listChip: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '15px',
    '& > *': {
      margin: theme.spacing(0.5),
      fontFamily: 'Dosis',
      paddingRight: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      '& > :not(:last-child)':{
        borderRight: '1px solid grey',
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    
  },
}));

const Home = () => {
  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }

  const { user } = useSessionContext();

  const classes = useStyles(theme);

  const [statistics, setStatistics] = useState({
    number_of_candidates: 0,
    number_of_jobs: 0,
    number_of_matches: 0,
  });

  useEffect(() => {
    // Définir la fonction async pour récupérer les données
    const fetchData = async () => {
      try {
        const response = await fetch('/statistic/main');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStatistics(data.data); // Assurez-vous que la réponse du serveur correspond à cette structure
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    };

    fetchData();
  }, []);

  const today = format(new Date(), 'PPP', { locale: fr });
  return (
    <main className={classes.main}>
      {NewsCarousel()}
      <div className={classes.bgHero}>
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          interval={7000}
        >
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg1})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg2})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg3})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg4})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>

        </Carousel>
        <Container maxWidth={false} className={classes.homeContainer}>
          <Box  className={classes.containerHomeText}>
            <div className={classes.panelSlogan}>
              <Typography variant="h1" color='primary' className={classes.h1Slogan}>
                Talents et compétences <span className={classes.pink}>francophones</span>
                <br />
                Collaborer autrement : <span className={classes.pink}>recruter, prêter, investir</span>
              </Typography>
              <div className={classes.listChip}>
                <span>Recrutement international de francophones</span>
                <span>Immigrant investisseur</span>
                <span>Prêt de personnel entre entreprises</span>
                <span>Sous-Traitance internationale</span>
              </div>
              
            </div>
            <div className={classes.panelAction}>
              {!user && (
                <List className={classes.btnDisplayFlex}>
                    <ListItem>
                      <SignupMenu/>
                    </ListItem>
                    <ListItem
                    >
                       <Fab 
                        variant="extended" 
                        size="large"
                        title="demo"
                        className={classes.iconAction}
                        >
                        <Link className={classes.linkDemo} href={"https://calendly.com/moovejob/demo"} target="_blank">
                          Demander une démo
                        </Link>
                      </Fab>
                    </ListItem>
                </List>
              )}
            </div>
          </Box>
        </Container>
      </div>
      <div>
        <Container
          maxWidth={false}
          className={classes.scrollWithOffset}
          id="statistic"
        >
          <Box className={classes.flexHeightDesktop}>
            <Typography variant="h2" className={classes.subTitle}>
              Nos Chiffres le {today}
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={12} md={4}>
                <img src={iconMapCandidate} alt="logo" className={classes.logoStyle} />

                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_user_metier} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de Compétences métiers inscrites
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img src={iconInterconnexionJob} alt="logo" className={classes.logoStyle} />
                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_jobs} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de Métiers différents
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img src={iconEnterpriseCandidateMatch} alt="logo" className={classes.logoStyle} />
                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_matches} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de matchs Entreprises-Candidats
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  dans les 30 derniers jours
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div>
        <Grid container>
          <Typography variant="h2" color="secondary" className={classes.subTitle}>
          Ouvrez-vous au monde!
          </Typography>
          <Grid item xs={12} md={6}>
            <div className={classes.videoContainer}>
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/RPqx5pTE38k"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container
              maxWidth={false}
              className={classes.scrollWithOffset}
              id="reves"
            >
              <Box className={`${classes.flexHeightDesktop} ${classes.bgGrey} ${classes.reveContainer}`}>
                <Typography variant="h2" className={classes.secondSubTitle}>
                  Nos 3 grands <span color='secondary'>rêves</span>
                </Typography>
                

                <List className={classes.listMooveContainer}>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Offrir la possibilité aux personnes ayant le rêve de
                      partir travailler et vivre à l’international de le
                      réaliser.
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Rendre accessible le recrutement international à toutes
                      les entreprises sans exception pour leur permettre de
                      continuer leur développement et leur croissance en créant
                      de la richesse.
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Contribuer significativement au rééquilibrage social
                      mondial par le reversement systématique d’un pourcentage
                      sur toutes les transactions au profit des organismes
                      mondiaux aidés.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: '15vh' }} className={classes.bgGrey}>
        <Container
          maxWidth={false}
          className={classes.scrollWithOffset}
          id="about"
        >
          <Box className={classes.flexHeightDesktop}>
            <Typography variant="h2" className={classes.subTitle}>
              À propos
            </Typography>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                        <Avatar alt="target" src={target} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Mission"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                              Faciliter la connexion des compétences francophones pour les candidats, les entreprises, les travailleurs autonomes ou les immigrants investisseurs, le tout sans intermédiaire! En associant les compétences des uns ou besoins et souhaits des autres pour leur permettre de créer ensemble de la valeur.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="rocket" src={rocket} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Type d’opportunités offertes"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              > La plateforme offre un accès simplifié à des talents internationaux pour les entreprises, et aux candidats, une porte vers des emplois, des contrats à l'étranger, directement et sans intermédiaires.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                      <Avatar alt="vision" src={vision} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Vision"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                              Devenir la référence mondiale en mobilité internationale des compétences en proposant un outil simple, intuitif et efficace pour connecter les humains entre eux au niveau professionnel.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="algo" src={algo} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Algorithme unique"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              >
                                Notre algorithme a été conçu à partir d'une matrice de plus de 1600 métiers et de près de 30 000 compétences professionnelles et techniques spécifiques à chaque métier.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                      <Avatar alt="strength" src={strength} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Force"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                            Notre plateforme offre aussi un accompagnement à la carte pour l'entreprise et pour le futur collaborateur de celle-ci selon leurs attentes dans l’objectif de simplifier les procédures, l’intégration, et ce pendant tout le processus de recrutement et d'immigration.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="handshake" src={handshake} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Réseau de partenaires"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              >
                                Notre réseau de partenaires propose des services exclusifs pour vous accompagner à chaque étape du processus de recrutement. Bénéficiez d'un suivi sur mesure par des experts qualifiés et obtenez des conseils spécifiquement adaptés à votre secteur d'activité.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            
          </Box>
        </Container>
      </div>
    

      <Grid container  style={{ marginTop: '30px', marginBottom: '5vh', backgroundColor: 'white' }}  spacing={2}>
        <Grid item xs={12} md={12} >
            <div className={classes.referenceContainer}>
              <Typography variant="h2" color="secondary" className={classes.subTitle}>
                Parrainez et soyez récompensé!
              </Typography>
              
            </div>
            <div className={classes.referenceContainer}>
              <img src={referencementIllu} alt="moovejob programme parrainage image" />
            </div>
            <div className={classes.referenceContainer}>
              <Typography variant="body2" className={classes.referenceText}>
                Gagnez 200 $ pour chaque candidat parrainé et embauché.
              </Typography>
            </div>
            <div className={classes.referenceContainer}>
              <Typography variant="body2" className={classes.referenceText}>
                Recommandez une entreprise et gagnez 10% de commission sur son abonnement annuel.
              </Typography>
            </div>
            <div className={classes.referenceContainer}>
              <Button
              size='sm'
                type="submit"
                className={classes.buttonStyle}
                onClick={()=>navigate('/parrainage-candidat')}
              >
                <Link className={classes.referenceBtnLink} >
                Parrainer un Candidat
                </Link>
              </Button>
              <Button
              size='sm'
                type="submit"
                className={classes.buttonStyle}
                onClick={()=>navigate('/parrainage-entreprise')}
              >
                <Link className={classes.referenceBtnLink} >
                Parrainer une Entreprise
                </Link>
              </Button>
            </div>
        </Grid>
        
      </Grid>

      <Grid container  style={{ marginTop: '15vh', backgroundColor: 'white' }} spacing={2}>
        <Grid item xs={12} md={6} className={classes.media}>
          <Hidden smDown>
            <img src={teamImage2} alt="moovejob team image" />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container
            maxWidth={false}
            className={`${classes.advantageContainer} ${classes.bgGrey}`}
            id="avantages"
          >
            <Box className={classes.flexHeightDesktop}>
              <Typography variant="h2" className={classes.secondSubTitle}>
                Les avantages moovejob pour les entreprises
              </Typography>
              <List>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Accès immédiat aux candidats et aux compétences recherchées.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Aucun intermédiaire.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réduction de vos frais de recrutement jusqu'à 80%.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réception instantanée d’une alerte dès qu'un candidat
                    répondant à vos critères de recherche est en ligne.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Affichage de vos postes et opportunités d’affaires à la communauté francophone mondiale, soit plus de 300 millions de personnes.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Outil unique, solutions multiples pour faire face à la pénurie de main-d’oeuvre.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Accompagnement professionnel si vous le souhaitez, aux étapes de votre choix (sélection, validation, immigration, accompagnement administratif, relocalisation, etc.).
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Notre plateforme se distingue par son exclusivité à la communauté francophone, à la mobilité internationale et par son algorithme de présélection, basé sur les compétences et les aptitudes personnelles et non plus uniquement sur les métiers.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Container>
        </Grid>
        
      </Grid>
      <Grid container  style={{ marginTop: '30px',marginBottom: '15vh', backgroundColor: 'white' }}  spacing={2}>
        <Grid item xs={12} md={6}>
          <Container maxWidth={false} className={`${classes.advantageContainer} ${classes.bgGrey}`}>
            <Box
              className={classes.flexHeightDesktop}
            >
              <Typography variant="h2" className={classes.secondSubTitle}>
                Les avantages moovejob pour les candidats
              </Typography>
              <List>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Accès immédiat aux emplois et aux stages disponibles dans
                    les pays francophones, selon votre sélection.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Totalement gratuit, même pour les travailleurs indépendants.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Aucun intermédiaire.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Aucun document à télécharger à l’exception de votre CV.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réception instantanée d’une alerte dès qu'une opportunité
                    d'emploi ou de stage vous correspondant est en ligne.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Accompagnement professionnel si vous le souhaitez, aux
                    étapes de votre choix (immigration, accompagnement
                    administratif, relocalisation, logement, finance, etc.).
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Notre plateforme se distingue par son exclusivité à la
                    mobilité internationale et par son algorithme de
                    présélection, basé sur les compétences et aptitudes
                    personnelles et non plus uniquement sur les métiers.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} className={classes.media}>
          <Hidden smDown>
            <img src={teamImage1} alt="moovejob team image" />
          </Hidden>
        </Grid>
        
      </Grid>
      <div className={classes.bgGrey}>
        <Container
          maxWidth="lg"
          className={classes.scrollWithOffset}
          id="topDestination"
        >
          <Box className={classes.containerPhoto}>
            <Typography variant="h2" className={classes.subTitle}>
              Top 10 des destinations des candidats
            </Typography>
            <Grid container className={classes.rowDestinationContainer} justify="space-between">
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>
                  <div className={classes.destinationImageContainer}>
                    <img src={canada} alt="destination-canada" className={classes.destinationImage} />
                  </div>

                  <Typography variant="h3" className={classes.subTitleDest}>
                    Canada
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={france} alt="destination-france" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    France
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={usa} alt="destination-usa" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    États-Unis
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>
                  <div className={classes.destinationImageContainer}>
                    <img src={belgium} alt="destination-belgique" className={classes.destinationImage} />
                  </div>

                  <Typography variant="h3" className={classes.subTitleDest}>
                    Belgique
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={luxembourg} alt="destination-Luxembourg" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    Luxembourg
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
            <Grid container className={classes.rowDestinationContainer}  justify="space-between">
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={switzerland} alt="destination-suisse" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    Suisse
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={greatBritain} alt="destination-grande-bretagne" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                   Angleterre
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={spain} alt="destination-espagne" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    Espagne
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={australia} alt="destination-australia" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    Australie
                  </Typography>
                </Grid>

              </Grid>
              <Grid item xs={12} md={2} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={germany} alt="destination-allemagne" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    Allemagne
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Container maxWidth={false}>
            <Box
              className={classes.flexHeightDesktop}
              id="partener"
              style={{ paddingBottom: '5vh' }}
            >
              <Typography variant="h2" className={classes.subTitle}>
                Nos Partenaires
              </Typography>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <a href="https://www.ville.quebec.qc.ca/" target='_blank'><img src={logoVDQ} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="https://www.patrimoine-rh.com/" target='_blank'><img src={logoPatrimoineRH} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="https://www.shawinigan.ca/" target='_blank'><img src={logoVilleShawinigan} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                
                

              </Grid>
              <Grid container style={{justifyContent:'center'}}>
                <Grid item xs={12} md={4}>
                  <a href="https://majorelle-mobility.com/" target='_blank'><img src={logoMajorelle} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="https://ulysse.com/?partnerId=patrimoine-rh" target='_blank'><img src={logoAirCanada} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="https://www.harfangimmigration.com/" target='_blank'><img src={logoHarfangImmigration} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                
              </Grid>
              <Grid container style={{justifyContent:'center'}}>
                <Grid item xs={12} md={4}>
                  <a href="https://www.desjardins.com/" target='_blank'><img src={logoDesJardins} alt="logo partenaire desjardins" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="https://www.lebonjack.ca/" target='_blank'><img src={logoLeBonJack} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
              </Grid>

            </Box>
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
};

export default Home;
12