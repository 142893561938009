import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  List,
  ListItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator';
import LoaderSpinner from '../UI-Component/LoaderSpinner';
import { apiUrl } from '../Functions/apiUrl';
import axios from 'axios';
import { errorHandling } from '../Functions/errorHandling';
import CloseIcon from '@material-ui/icons/Close';
import MetiersAutoComplete from './MetiersAutoComplete';
import PlacesAutoComplete from './PlacesAutoComplete';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  homeContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0 0 0 .5px rgba(50,50,93,.1),0 2px 5px 0 rgba(50,50,93,.1),0 1px 1.5px 0 rgba(0,0,0,.07)',
    borderRadius: '7px',
    padding: '20px',
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

const CorporateQuizForm = () => {
  const classes = useStyles();
  const [formErrors, setFormErrors] = useState({});
  const [estimatedCategory,setEstimatedCategory] = useState(null);
  const history = useHistory();

  const initialData = {
    contactName: '',
    phoneNumber: '',
    email: '',
    organizationName: '',
    city: null,
    disponibility: '',
    jobs: null,
    showSpinner: false,
    byWorkType: false,
  }
  const [formData, setFormData] = useState(initialData);
  const [open, setOpen] = useState(false);


  const saveForm = (data) => {
    const options = {
      url: `${apiUrl()}/save-corporate-form`,
      method: 'POST',
      data: data,
    };

    return axios(options)
      .then((response) => response)
       .catch((error) => {
         return errorHandling(error);
       });
  };


  const handleClose = () => {
    
    setTimeout(() => {
      setOpen(false);
      history.push("/");
    }, "1100");
  };

  const validateForm = (verifyAll = false) => {
    const errors = {};

    if (formData.contactName || verifyAll) {
      if (!formData.contactName) {
        errors.contactName = 'Le nom du contact est requis.';
      } else if (!validator.isLength(formData.contactName, { min: 2, max: 15 })) {
        errors.contactName = 'Le nom du contact doit contenir entre 2 et 15 caractères.';
      }
    }

    if (formData.phoneNumber || verifyAll) {
      if (!formData.phoneNumber) {
        errors.phoneNumber = 'Le champ Téléphone est requis.';
      }
    }

    if (formData.email || verifyAll) {
      if (!formData.email) {
        errors.email = 'L\'adresse courriel est requise.';
      } else if (!validator.isEmail(formData.email)) {
        errors.email = 'L\'adresse courriel n\'est pas valide.';
      } 
    }

    if (formData.organizationName || verifyAll) {
      if (!formData.organizationName) {
        errors.organizationName = 'Le nom de l\'organisation est requis.';
      } else if (!validator.isLength(formData.organizationName, { min: 2, max: 15 })) {
        errors.organizationName = "Le nom de l'organisation doit contenir entre 2 et 15 caractères.";
      }
    }

    if (formData.jobs || verifyAll) {
      if (!formData.jobs || formData.jobs.length==0) {
        errors.jobs = 'Vous devez sélectionner les métiers recherchés';
      }
    }

    if (formData.city || verifyAll) {
      if (!formData.city  || formData.city==null) {
        errors.city = 'Vous devez sélectionner la ville';
      }
    }

    if (estimatedCategory || verifyAll) {
      if (!estimatedCategory) {
        errors.estimatedCategory = 'L\'estimation du nombre d’entreprises participantes est requis';
      }
    }
    

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value,
      },
      validateForm(),
    );
  };

  const handleSelectChange = (event) => {
    setEstimatedCategory(event.target.value);
  };

  const handleSubmit = () => {
    if (!validateForm(true)) {
      return;
    }

    const data = {
        phone_number: formData.phoneNumber.substring(0,30),
        contact_name: formData.contactName,
        email: formData.email,
        organization_name: formData.organizationName,
        estimated_category: estimatedCategory,
        disponibility: formData.disponibility.substring(0,125),
        jobs: formData.jobs,
        city: formData.city[0]
    };


    setFormData({
      ...formData,
      showSpinner: true,
    });

    setTimeout(() => {
      saveForm(data)
      .then((response) => {
        if (response != null && response != undefined) {
          if (response.status === 400) {
            if (response.data.error === 'City not found') {
              setFormErrors({
                email: response.data.message,
                verifyAll: true,
              });
            }
          }
          if (response.status === 200) {
            setOpen(true);
            return
          }
        }
        setFormData({
          ...formData,
          showSpinner: false,
        });
      });
    }, "1100");

    
  };


  return (
      

    <>
      {formData.showSpinner && (
        <LoaderSpinner sizeOfSpinner={150} text="Envoi en cours"/>
      )}
      <Container maxWidth="md" className={classes.homeContainer}>
        <Box className={classes.bgHero}>
          <form className={classes.form}>
              <Typography variant="h2" color='secondary'>Formulaire de Contact - Offre Corporative</Typography>
              <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        Découvrez notre offre dédiée aux <label className={classes.bold}>regroupements d'entreprises</label> ! Bénéficiez de <label className={classes.bold}> tarifs dégressifs</label>, d'une équipe dédiée, et d'un accompagnement personnalisé pour maximiser votre visibilité. 
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                      Remplissez le formulaire ci-dessous pour entrer en contact avec nous !
                                      </Typography>
                                    </ListItem>
                                  </List>
              <Grid container spacing={2} className={classes.containerForm}>
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.organizationName}
                        required
                        fullWidth
                        value={formData.organizationName || ''}
                        label="Nom de votre organisation"
                        name="organizationName"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        autoComplete="organizationName"
                      />
                      {formErrors.organizationName && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.organizationName}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.contactName}
                        required
                        fullWidth
                        id="contactName"
                        value={formData.contactName || ''}
                        label="Nom de la personne contact"
                        autoComplete="given-name"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        name="contactName"
                      />
                      {formErrors.contactName && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.contactName}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.email}
                        required
                        fullWidth
                        value={formData.email || ''}
                        label="Courriel"
                        name="email"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        autoComplete="email"
                      />
                      {formErrors.email && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.email}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.phoneNumber}
                        required
                        fullWidth
                        value={formData.phoneNumber || ''}
                        label="Téléphone"
                        name="phoneNumber"
                        autoComplete="family-name"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        inputProps={{
                          'maxlength': 30
                        }}
                      />
                      {formErrors.phoneNumber && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.phoneNumber}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <PlacesAutoComplete
                        name="city"
                        label="Ville *"
                        max={1}
                        multiple
                        onFormChange={onFormChange}
                        errors={formErrors}
                        formData={formData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MetiersAutoComplete
                        userType={"Candidate"}
                        limit={10}
                        name="jobs"
                        multiple
                        onFormChange={onFormChange}
                        errors={formErrors}
                        formData={formData}
                        label="Les métiers recherchés par vos membres *"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel error={!!formErrors.estimatedCategory} id="estimatedCategory-label">Nombre d’entreprises participantes (estimatif) *</InputLabel>
                        <Select
                          labelId="estimatedCategory-label"
                          id="estimatedCategory"
                          value={estimatedCategory}
                          onChange={(event) => handleSelectChange(event)}
                          label="Nombre d’entreprises participantes (estimatif) *"
                          error={!!formErrors.estimatedCategory}
                        >
                          <MenuItem value={1}>Entre 5 et 10</MenuItem>
                          <MenuItem value={2}>Entre 11 et 20</MenuItem>
                          <MenuItem value={3}>Entre 21 et 50</MenuItem>
                          <MenuItem value={4}>Plus de 50</MenuItem>
                        </Select>
                      </FormControl>
                      {formErrors.estimatedCategory && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.estimatedCategory}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="À quel moment est-il plus facile de vous contacter?"
                        value={formData.disponibility}
                        name="disponibility"
                        onChange={(event) => onFormChange(event)}
                        variant="outlined"
                        inputProps={{
                          'maxlength': 125
                        }}
                      />
                    </Grid>
                  </>
                  
                  <Grid item xs={12}>
                    <Button
                      onClick={handleSubmit}
                      disabled={false}
                      className={classes.submit}
                    >
                      Envoyer ma demande
                    </Button>
                  </Grid>
                  
            </Grid>
          </form>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '18px 24px',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Typography variant='h3' color='secondary' style={{textAlign:'center',margin:'5px 0px'}}>
              Merci ! Nous avons bien reçu votre formulaire pour notre offre corporative.
            </Typography>
            <Typography gutterBottom style={{textAlign:'center'}}>
              Notre équipe vous contactera par courriel dans les prochains jours pour discuter de vos besoins et vous fournir plus d'informations sur notre programme.
            </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CorporateQuizForm;
