import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getCountryList = () => (
  axios.get(`${apiUrl()}/places/countries`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getCountryList;
