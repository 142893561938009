import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, Card, CardContent, Checkbox, FormGroup, FormControlLabel, Button } from '@material-ui/core';
import Footer from '../UI-Component/Footer';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import useSessionContext from '../sessions/useSessionContext';
import LoaderSpinner from '../UI-Component/LoaderSpinner';
import { apiUrl } from '../Functions/apiUrl';
import { errorHandling } from '../Functions/errorHandling';
import axios from 'axios';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "100px",
    },
    rootCard: {
      width: '100%',
    },
    homeContainer: {
      backgroundColor: "whitesmoke",
      paddingTop: "40px",
      paddingBottom: "50px"
    },
    details: {
      fontWeight: 'normal'
    },
    heading: {

    },
    rose: {
      color: theme.palette.secondary,
    },
    textCenter: {
      textAlign: 'center'
    },
    submitButton: {
      minWidth: '100px'
    },
  }),
);

const EmailPreference = (props) => {
  const { type, token } = useParams();
  const { SnackBar, user } = useSessionContext();
  const { informError, informSuccess } = SnackBar;
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const [formData, setFormData] = useState({
    unsubscribe_all: true,
    receive_notifications: false,
    receive_reports: false,
    receive_communications: false,
    receive_newsletters: false,
    email: ''
  });

  if (user) {
    history.push('/mon-compte/parametre');
  }

  const getData = () => {
    const options = {
      url: `${apiUrl()}/email_preferences/${type}/${token}`,
      method: 'GET',
    };

    return axios(options)
      .then((response) => response)
      .catch((error) => {
        return errorHandling(error);
      });
  };


  const onCheckBoxChange = ({ target }) => {
    const { name, checked } = target;
    if (name != 'unsubscribe_all') {
      setFormData((formData) => ({
        ...formData,
        [name]: checked,
        unsubscribe_all: false
      }));
    } else {
      setFormData({
        unsubscribe_all: true,
        receive_notifications: false,
        receive_communications: false,
        receive_reports: false,
        receive_newsletters: false,
      });
    }
  };
  const fetchData = async () => {

    try {
      getData().then((response) => {
        if (response !== null && response !== undefined) {
          if (response.status === 200) {
            setNotFound(false);
            if (type == 'emailing') {
              let preferences = response.data.data
              setFormData({
                unsubscribe_all: !preferences.receive_notifications && !preferences.receive_reports && !preferences.receive_communications && !preferences.receive_newsletters,
                ...preferences
              })
            } else {
              axios({
                url: `${apiUrl()}/unsubscribe/to/mailings/list`,
                method: 'POST',
                data: {
                  email: response.data.email
                }
              }).then((response) => {
                setLoading(false);
                if (response !== null && response !== undefined) {
                  if (response.status === 200) {
                    setEmail(response.data.email);
                    setNotFound(false);
                  } else {
                    informError('Une erreur est survenue');
                    setNotFound(true);
                  }
                }
              });
            }
          } else {
            setNotFound(true)
          }
        }
        setLoading(false);
      });
    } finally { }
  };

  const handleSubmit = () => {
    const options = {
      url: `${apiUrl()}/email_preferences/`,
      method: 'POST',
      data: {
        preferences: {
          token: token,
          type: type,
          ...formData,
        }
      }
    };
    axios(options).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          informSuccess('Vos préférences ont été enregistrés !');
        } else {
          informError('Une erreur est survenue');
          setNotFound(true);
        }
      }else{
        informError('Une erreur est survenue');
      }
    })
    .catch((error) => {
      return errorHandling(error);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
          <Grid container className={classes.homeContainer}>
            <Grid item md={4} xs={12}>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item lg={12} className={classes.faqContainer}>
                {!notFound && (
                  <div>
                    <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>Gérer les préférences d'emails</Typography>
                  </div>
                )}
                <Grid container >
                  <Card square className={classes.rootCard} elevation={0} variant="outlined">
                    <CardContent>
                      {loading && (
                        <LoaderSpinner sizeOfSpinner={150} text="Chargement" />
                      )}
                      {!notFound ? (
                        <>
                          {type == 'emailing' ? (
                            <>
                              <Grid item xs={12}>
                                <Typography variant="h6" className={classes.typography}>
                                  Choisissez quel email vous souhaitez recevoir :
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.receive_notifications}
                                        onChange={onCheckBoxChange}
                                        name="receive_notifications"
                                      />
                                    }
                                    label="Notifications et alertes"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.receive_reports}
                                        onChange={onCheckBoxChange}
                                        name="receive_reports"
                                      />
                                    }
                                    label="Rapports et suivis"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.receive_communications}
                                        onChange={onCheckBoxChange}
                                        name="receive_communications"
                                      />
                                    }
                                    label="Nos communications et offres promotionelles"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.receive_newsletters}
                                        onChange={onCheckBoxChange}
                                        name="receive_newsletters"
                                      />
                                    }
                                    label="Newsletters"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.unsubscribe_all}
                                        onChange={onCheckBoxChange}
                                        name="unsubscribe_all"
                                      />
                                    }
                                    label="Se désinscrire de tous les e-mails"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} className={classes.textCenter}>
                                <Button size="small" className={classes.submitButton} onClick={handleSubmit}>
                                  Mettre à jour
                                </Button>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Typography variant="h2" color="secondary">
                                Vous allez nous manquer
                              </Typography>
                              <Typography variant="h6" className={classes.typography}>
                                Vous vous êtes désabonné de notre liste de diffusion.
                              </Typography>
                            </>
                          )}


                        </>
                      ) : (
                        <>
                          <Typography variant="h2" color="secondary">
                            Désolé, cette page n'est pas disponible.
                          </Typography>
                          <Typography variant="h6" className={classes.typography}>
                            Le lien que vous avez suivi est peut-être rompu ou la page a peut-être été supprimée
                          </Typography>
                          <Grid item xs={12} className={classes.textCenter}>
                            <Button size="small" className={classes.submitButton} onClick={() => { history.push('/') }}>
                              Revenir à la page d'accueil
                            </Button>
                          </Grid>
                        </>
                      )}


                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default EmailPreference;
